import { find, propEq } from 'ramda';
import React from 'react';
import "./InputField.scss";

/**
 * select field component
 * @param {*} props should have a title, a onChange-function, a value (or a placeholder) and possible values array with an unique id and a name per item
 * @returns a select field
 */
export const SelectField = props => {
  const possibleValues = props.possibleValues ? props.possibleValues : [{id: 0, title: "Auswählen..."}];
  return (<div className="input-group">
    <label className="input-title">
      { props.title }
    </label>
    <select 
      className={`select-field ${props.className}`} 
      onChange={e => props.returnObject ? props.onChange(find(propEq("title", e.target.value), possibleValues)) : props.onChange(e.target.value) }
      value={props.value.title}
      disabled={props.disabled ? true : false}
      >
      { props.defaultValue ? <option>{ props.defaultValue.title }</option> : null }
      { possibleValues
            .map(item => <option key={ item.id } >{ item.title }</option>) }       
    </select>
  </div>
  );
}