import { useState } from "react";
import { fetchPost } from "../../../utils/apiUtils";

const importSchedule = async data => {
    const postData = async (data) => {
        const res = await fetchPost("/import/schedule", data);
        if(res.status === 200) return true
        return false;
    }
    return postData(data);
}

export const dataImportReducer = (state, action) => {
    switch(action.type) {
        case 'SEND_DATA':
            if(state.hasSend === false) {
                state.hasSend = true;
                importSchedule(state.data).then(() => {
                    state.setPage(4);
                });
            }
            break;
        default:
            return false;
    }
}

export const useDataBind = () => {
    const [value, setVal] = useState(DEFAULT_STATE);
    const onChange = (newVal) => setVal(newVal);
    const isSet = value !== DEFAULT_STATE;
    return {value, isSet, onChange};
}

export const DEFAULT_STATE = {id: 0, title: "Auswählen..."};

export const POSSIBLE_UNIVERSITYS = [
    DEFAULT_STATE,
    {
        title: "Universität Bremen",
        faculties: [
            DEFAULT_STATE,
        ]
    },
    {
        title: "Hochschule Bremen",
        faculties: [
            DEFAULT_STATE,
            {
                title: "Fakultät 4: Elektrotechnik und Informatik",
                courses: [
                    DEFAULT_STATE,
                    {
                        title: "IS Medieninformatik B. Sc.",
                        semester: [
                            {
                                title: "1. Fachsemester",
                                modules: [
                                    {
                                        id: 0,
                                        date: "2021-10-05",
                                        beginHour: "09:45",
                                        endHour: "11:15",
                                        room: "I012",
                                        subject: {
                                            id: 0,
                                            title: "Grundlagen der Mathematik",
                                            lecturer: "Prof. Dr. Martin Hering-Bertram"
                                        }
                                    },
                                    {
                                        id: 0,
                                        date: "2021-10-06",
                                        beginHour: "09:45",
                                        endHour: "11:15",
                                        room: "I032a-b",
                                        subject: {
                                            id: 0,
                                            title: "Grundlagen der Informatik",
                                            lecturer: "Prof. Dr. Helmut Eirund"
                                        }
                                    },
                                    {
                                        id: 0,
                                        date: "2021-10-07",
                                        beginHour: "09:45",
                                        endHour: "11:15",
                                        room: "I012",
                                        subject: {
                                            id: 0,
                                            title: "Programmieren I",
                                            lecturer: "Prof. Dr. Helmut Eirund"
                                        }
                                    },
                                    {
                                        id: 0,
                                        date: "2021-10-08",
                                        beginHour: "09:45",
                                        endHour: "11:15",
                                        room: "I032a-b",
                                        subject: {
                                            id: 0,
                                            title: "Gestaltung I",
                                            lecturer: "Andreas Teufel"
                                        }
                                    },
                                    {
                                        id: 0,
                                        date: "2021-10-04",
                                        beginHour: "08:00",
                                        endHour: "09:30",
                                        room: "I032a-b",
                                        subject: {
                                            id: 0,
                                            title: "Medieninformatik 1",
                                            lecturer: "Prof. Dr. Benjamin Tannert"
                                        }
                                    }
                                ]
                            },
                            {
                                title: "2. Fachsemester",
                                modules: [
                                    {
                                        id: 0,
                                        date: "2021-10-04",
                                        beginHour: "09:45",
                                        endHour: "11:15",
                                        room: "I032a-b",
                                        subject: {
                                            id: 0,
                                            title: "Rechnernetze",
                                            lecturer: "Prof. Dr. rer. nat. Richard Sethmann"
                                        } 
                                    },
                                    {
                                        id: 0,
                                        date: "2021-10-05",
                                        beginHour: "09:45",
                                        endHour: "11:15",
                                        room: "I032a-b",
                                        subject: {
                                            id: 0,
                                            title: "Angewandte Mathematik",
                                            lecturer: "Prof. Dr. Peter Krug"
                                        }
                                    },
                                    {
                                        id: 0,
                                        date: "2021-10-06",
                                        beginHour: "09:45",
                                        endHour: "11:15",
                                        room: "I032a-b",
                                        subject: {
                                            id: 0,
                                            title: "Programmieren II",
                                            lecturer: "Prof. Dr. Helmut Eirund"
                                        }
                                    },
                                    {
                                        id: 0,
                                        date: "2021-10-07",
                                        beginHour: "09:45",
                                        endHour: "11:15",
                                        room: "I032a-b",
                                        subject: {
                                            id: 0,
                                            title: "Medieninformatik II",
                                            lecturer: "Prof. Dr. Pälke"
                                        }
                                    },
                                    {
                                        id: 0,
                                        date: "2021-10-08",
                                        beginHour: "09:45",
                                        endHour: "11:15",
                                        room: "I032a-b",
                                        subject: {
                                            id: 0,
                                            title: "Gestaltung II",
                                            lecturer: "Andreas Teufel"
                                        }
                                    }
                                ]
                            },
                            {
                                title: "3. Fachsemester",
                                modules: [
                                    {
                                        id: 0,
                                        date: "2021-10-05",
                                        beginHour: "08:00",
                                        endHour: "09:30",
                                        room: "I012",
                                        subject: {
                                            id: 0,
                                            title: "Medienformate und Algorithmen",
                                            lecturer: "Dr. Peter Krug"
                                        }
                                    },
                                    {
                                        id: 0,
                                        date: "2021-10-06",
                                        beginHour: "09:45",
                                        endHour: "11:15",
                                        room: "I032C",
                                        subject: {
                                            id: 0,
                                            title: "Softwaretechnik",
                                            lecturer: "Prof. Dr. Thorsten Teschke"
                                        }
                                    },
                                    {
                                        id: 0,
                                        date: "2021-10-06",
                                        beginHour: "11:30",
                                        endHour: "16:45",
                                        room: "I032C",
                                        subject: {
                                            id: 0,
                                            title: "Wagenfeld von A-Z",
                                            lecturer: "Andreas Teufel"
                                        }
                                    },
                                    {
                                        id: 0,
                                        date: "2021-10-07",
                                        beginHour: "09:45",
                                        endHour: "11:15",
                                        room: "I032C",
                                        subject: {
                                            id: 0,
                                            title: "Human Computer Interaction",
                                            lecturer: "Prof. Dr. Volker Paelke"
                                        }
                                    },
                                    {
                                        id: 0,
                                        date: "2021-10-08",
                                        beginHour: "09:45",
                                        endHour: "15:00",
                                        room: "I220",
                                        subject: {
                                            id: 0,
                                            title: "PICKIT",
                                            lecturer: "Prof. Dr. Volker Paelke"
                                        }
                                    },
                                    {
                                        id: 0,
                                        date: "2021-10-04",
                                        beginHour: "09:45",
                                        endHour: "11:15",
                                        room: "I032a-b",
                                        subject: {
                                            id: 0,
                                            title: "Informationssicherheit",
                                            lecturer: "Prof. Dr. rer. nat. Richard Sethmann"
                                        }
                                    },
                                    {
                                        id: 0,
                                        date: "2021-10-08",
                                        beginHour: "09:45",
                                        endHour: "15:00",
                                        room: "I221",
                                        subject: {
                                            id: 0,
                                            title: "Filmproduktion",
                                            lecturer: "Ralf Carsten Schreier"
                                        }
                                    }
                                ]
                        
                            },
                            {
                                title: "4. Fachsemester",
                                modules: [
                                    {
                                        id: 0,
                                        date: "2021-10-04",
                                        beginHour: "08:00",
                                        endHour: "09:45",
                                        room: "I033b",
                                        subject: {
                                            id: 0,
                                            title: "Computer Graphics",
                                            lecturer: "Prof. Dr. Martin Hering-Bertram"
                                        }
                                    },
                                    {
                                        id: 0,
                                        date: "2021-10-05",
                                        beginHour: "08:00",
                                        endHour: "09:45",
                                        room: "I033b",
                                        subject: {
                                            id: 0,
                                            title: "Datenbankbasierte Web-Anwendungen",
                                            lecturer: "Prof. Dr. Benjamin Tannert"
                                        }
                                    },
                                    {
                                        id: 0,
                                        date: "2021-10-06",
                                        beginHour: "11:30",
                                        endHour: "13:00",
                                        room: "I033b",
                                        subject: {
                                            id: 0,
                                            title: "Telekollaboration",
                                            lecturer: "Prof. Dr. Pälke"
                                        }
                                    },
                                    {
                                        id: 0,
                                        date: "2021-10-06",
                                        beginHour: "09:45",
                                        endHour: "16:45",
                                        room: "I033b",
                                        subject: {
                                            id: 0,
                                            title: "Interaction Design",
                                            lecturer: "Andreas Teufel"
                                        }
                                    },
                                    {
                                        id: 0,
                                        date: "2021-10-07",
                                        beginHour: "09:45",
                                        endHour: "15:00",
                                        room: "I222",
                                        subject: {
                                            id: 0,
                                            title: "Motion Design",
                                            lecturer: "Hölscher"
                                        }
                                    },
                                    {
                                        id: 0,
                                        date: "2021-10-08",
                                        beginHour: "09:45",
                                        endHour: "11:15",
                                        room: "I222",
                                        subject: {
                                            id: 0,
                                            title: "Video und Film",
                                            lecturer: "Gitta Noll"
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        title: "Hochschule Bremerhaven",
        faculties: [
            DEFAULT_STATE,
        ]
    },
];