import { useState, useReducer } from "react";
import { find, without } from 'ramda';
import { useDataBind, dataImportReducer, DEFAULT_STATE, POSSIBLE_UNIVERSITYS } from './utils/onboardingUtils';
import { login, getAuthToken } from "../../utils/tokenUtils";
import { Button } from "../../components/form/Button";
import { SelectField } from "../../components/form/SelectField";
import "./Onboarding.scss";
import calendarIcon from "../../assets/calendar-icon.svg";
import universityIcon from "../../assets/university-icon.svg";
import ui from "../../assets/ui.svg";

const Dots = props => {
    return <div className="dots">{ props.children }<span className="line"></span></div>;
}

const Dot = props => {
    return <span className={`dot ${props.isActive ? "is-active" : "is-inactive"}`}></span>;
}

const MultiPage = props => {
    const dots = props.children.map((child, key) => <Dot isActive={ key === props.activePage } />);
    return <>
        { props.children }
        <Dots>
            { dots } 
        </Dots>
    </>;
}

const Page = props => {
    return <>{ props.activePage === props.isPage ? props.children : null }</>;
}

const Module = props => {
    const [ isActive, setIsActive ] = useState(find(m => m === props.module, props.modules) === undefined);

    const addModule = () => {
        setIsActive(!isActive);
        props.addModule(props.module);
    }

    return  <span
        key={ props.module.subject.title }
        className={ `module is-${isActive ? "inactive" : "active"}` }
        onClick={ () => addModule() }>
            { props.module.subject.title }
            <span>
                <i class="remove-icon bi bi-check-circle"></i>
                <i class="add-icon bi bi-plus-circle"></i>
            </span>
    </span>
}

const SemesterArea = props => {
    const [show, setShow] = useState(false);

    const semester = props.semester;
    const modules = props.modules;
    const setModules = props.setModules;

    const addModule = module => {
        const foundModule = find(m => m === module, modules);
        if(foundModule === undefined) {
            modules.push(module);
            setModules(modules);
        } else {
            const newList = without([foundModule], modules);
            setModules(newList);
        }
    }

    return  <div className={ `semester-area ${ show ? "is-active" : "is-inactive" }` }>
                <span className="title" onClick={ () => setShow(!show) }>{ semester.title }</span>
                <div className="module-list">
                    { show ? 
                        semester.modules.map(
                            module => <Module module={module} modules={modules} addModule={addModule}/>)
                        : undefined }
                </div>
            </div>;
}



export const Onboarding = props => {
    const [ page, setPage ] = useState(0);
    const [ modules, setModules ] = useState([]);

    const university = useDataBind();
    const faculty = useDataBind();
    const course = useDataBind();

    const setUniversity = (newVal) => {
        faculty.onChange(DEFAULT_STATE);
        course.onChange(DEFAULT_STATE);
        university.onChange(newVal);
    }

    const setFaculty = (newVal) => {
        course.onChange(DEFAULT_STATE);
        faculty.onChange(newVal);
    }

    const [ importer, importData ] = useReducer(dataImportReducer, { hasSend: false, data: modules, setPage: setPage});

    const doImportData = () => {
        importData({ type: 'SEND_DATA' });
    }

    return <div className="onboarding">
            <MultiPage activePage={page}>
                <Page activePage={page} isPage={ 0 }>
                    <img src={ universityIcon } width={ 100 } alt={ "icon" }/>
                    <h3>Willkommen bei 4DO</h3>
                    <p>Alle Termine und Aufgaben an einem Ort.<br /><br />Für einen einfachen Einstieg, kannst du nun deinen Stundenplan importieren.</p>

                    <div className="btns">
                            <Button
                                value="Weiter"
                                className="btn-primary w-100 mt-25"
                                onClick={ () => setPage(1) }
                            />
                        </div>
                </Page>
                <Page activePage={page} isPage={ 1 }>
                    <img src={ universityIcon } width={ 100 } alt={ "icon" }/>
                    <h3>Importiere deinen Stundenplan</h3>
                    <p>Wähle deine Hochschule und deinen Studiengang aus, um deinen Stundenplan zu importieren.</p>

                    <form>
                        <SelectField 
                            possibleValues={ POSSIBLE_UNIVERSITYS }
                            value={ university.value }
                            onChange={ obj => setUniversity(obj) }
                            title="Universität / Hochschule"
                            className="w-100"
                            returnObject
                        />

                        <SelectField 
                            possibleValues={ university === DEFAULT_STATE ? [ DEFAULT_STATE ] : university?.value?.faculties }
                            value={ faculty.value }
                            onChange={ obj => setFaculty(obj) }
                            title="Fakultät"
                            className="w-100"
                            disabled={ !university.isSet }
                            returnObject
                        />

                        <SelectField 
                            possibleValues={ faculty === DEFAULT_STATE ? [ DEFAULT_STATE ] : faculty?.value?.courses }
                            value={ course.value }
                            onChange={ course.onChange }
                            title="Studiengang"
                            className="w-100"
                            disabled={ !faculty.isSet }
                            returnObject
                        />

                        <div className="btns">
                            <Button
                                value="Weiter"
                                className="btn-primary w-100 mt-25"
                                disabled={ !course.isSet } 
                                onClick={ () => setPage(2) }
                            />
                        </div>
                    </form>
                </Page>
                <Page activePage={page} isPage={ 2 }>
                    <img src={ calendarIcon } width={ 100 } alt={ "icon" }/>
                    <h3>Wähle deine Module</h3>
                    <p>Wähle die Module, die du dieses Semester belegst, damit wir deinen aktuellen Stundenplan importieren können.</p>
                    
                    <div className="semester">
                        { course.value !== DEFAULT_STATE ? course.value.semester.map(semester => {
                            return <SemesterArea key={semester.title} semester={semester} modules={modules} setModules={setModules} />;
                        }) : undefined }
                    </div>

                    <div className="btns">
                            <Button
                                value="Importieren"
                                className="btn-primary w-100 mt-25"
                                disabled={ !course.isSet } 
                                onClick={ () => {setPage(3); doImportData();} }
                            />
                        </div>
                </Page>
                <Page activePage={page} isPage={ 3 }>
                    <img src={ calendarIcon } width={ 100 } alt={ "icon" }/>
                    <h3 id="import-loader">Daten werden importiert</h3>
                    <div className="loading-spinner sneaky"></div>
                </Page>
                <Page activePage={page} isPage={ 4 }>
                    <img src={ calendarIcon } width={ 100 } alt={ "icon" }/>
                    <h3>Eigene Einträge anlegen</h3>
                    <p>Über die "<i className="bi bi-plus-lg"></i>"-Buttons kannst du deiner To Do Liste und deinem Kalendar eigene Einträge hinzufügen.</p>
                    <br /><br />
                    <img src={ ui } width={ "100%" } alt={ "icon" }/>

                    <div className="btns">
                            <Button
                                value="Zur App »"
                                className="btn-primary w-100 mt-25"
                                disabled={ !course.isSet } 
                                onClick={ () => {login(getAuthToken());} }
                            />
                        </div>
                </Page>
            </MultiPage>
        </div>;
}
